@import url(https://fonts.googleapis.com/css?family=Raleway);
body {
  margin: 0;
  color: white;
  height: 100vh;
  width: 100vw;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: visible;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
*{
  -ms-overflow-style: none;
}

.brandName{
  padding-left: 2vw;
  font-family: "Raleway";
  font-size: x-large;
}

h1 {
  font-family: "Raleway";
  font-size: 50px;
  text-align: left;
  padding-left: 2vw;
  animation: text-focus-in 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530);
}

.container {
  text-align: center;
  min-width: 100vw;
}

.nav {
  background: rgba(0, 0, 0, 0.5);
  min-width: 100vw;
  color: white;
  position: sticky;
  top: 0;
  font-family: "Raleway";
  display: flex;
  justify-content: space-between;
  text-align: center;
  vertical-align: middle;
}

.nav ul {
  text-align: center;
  list-style: none;
  display: flex;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav li.active {
  color: white;
}

.nav li {
  transition: 0.1s;
  color: grey;
  font-size: 20px;
  text-decoration: none;
  padding: 24px 10px;
}

.nav li:hover {
  font-style: italic;
}

.waitlistButton {

  font-family: "Raleway";
  color: lightgrey;
  outline: 0;
  background: none;
  font-size: 25px;
  border-radius: 5px;
  border: 2px solid white;
  align-items: center;
  text-align: center;
  z-index: 3;
  margin-top: 10vh;
  margin-bottom: 20px;
  padding: 0 25px;
  height: 48px;
  transition: 0.5s;
}

.waitlistButton:hover {
  color: white;
  box-shadow: 0 0 26px #28C8EC;
}

.scrollDown{
  position: relative;
  text-align: center;
  margin-top: 100px;
  align-items: center;
  animation: updown 0.5s infinite alternate;
}

.socialTab{
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 2vw;
  justify-content: space-between;
  vertical-align: center;
  width: fit-content;
  background-color: rgba(200,200,200, 0.95);
  border-radius: 5px;
}
.textDesc{
  color: white;
  position: relative;
  top: 50%;
  font-family: "Raleway";
  font-size: 30px;
  font-weight: bold;
  margin-left: 20px;
}


